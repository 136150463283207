:root {
  --cybergreen: #34FF00;
  --cybergold: #FFB800;
  --lightgreen: #E5FFDF;
  --lightgray: #E7E7E7;
  --mediumgray: #B5B5B5;
  --darkgray: #242424;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

textarea::selection {
  background-color: white;
  color: var(--cybergreen);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--cybergold);
  border-radius: 10px;
}

.no-scroll {
  overflow: hidden;
}